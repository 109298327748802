var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('transition', {
    attrs: {
      "name": "back-to-top-fade"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visible,
      expression: "visible"
    }],
    staticClass: "vue-back-to-top",
    style: "bottom:".concat(_vm.bottom, ";right:").concat(_vm.right, ";"),
    on: {
      "click": _vm.backToTop
    }
  }, [_vm._t("default", function () {
    return [_c('div', {
      staticClass: "default"
    }, [_c('span', [_vm._v(" " + _vm._s(_vm.text) + " ")])])];
  })], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }